import { linkSync } from "fs"
import { graphql, Link, useStaticQuery } from "gatsby"
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews"
import React, { PropsWithChildren, useState } from "react"
import { isHomepage, isPage } from "../../helper/helper"
import * as styles from "./footer.module.scss"

interface FooterProps {
  contactInformation: JSX.Element | null
  columns: JSX.Element[]
}

export default function Footer(props: FooterProps) {
    return (<section className={styles.footerWrapper}>
      <div className={styles.footerContainer}>
        {props.columns.map((i, idx) =>
          <div key={idx} className={styles.contactColumn}>
            {i}
          </div>
        )}
      </div>
    </section>)
}

