import React from "react"
import {PageProps, graphql} from "gatsby"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import EventHeader from "../slices/events/EventHeader"
import { BasePage } from "./BasePage"
import { LanguageConfig } from "./page"
import { getLangDisplayString, getLangPrefix, linkResolver } from "../helper/helper"
import { LangContext, parseLanguage } from "../helper/lang"
import { PrismicRichText, SliceZone } from "@prismicio/react"
import { components } from "../slices"

interface PageContext {
  prismicId: string
  lang: string
}


function Page(data: PageProps<Queries.Query, PageContext>) {
  const contactInformation = data.data.prismicFooter?.data.contact_information;

  const slices = data.data.prismicEvent?.data.slices

  const alternateLanguagePages = data.data.prismicEvent?.alternate_languages.map(i => i.document).filter((i): i is Queries.PrismicEvent=> i?.type == "event").reduce((prev, curr) => {
    return {
      ...prev,
      [curr.lang]: linkResolver(curr)
    }
  }, {} as Record<string, string>) ?? {}

  const languageConfig: Record<string, LanguageConfig> = [...new Set(data.data.allPrismicHomepage.edges.map(i => i.node.lang))].reduce((prev, curr) => {
    return {
      ...prev,
      [curr]: {
        displayString: getLangDisplayString(curr),
        urlPrefix: alternateLanguagePages[curr] ?? getLangPrefix(curr),
        isActive: curr == data.pageContext.lang
      }
    }
  }, {} as Record<string, LanguageConfig>)

  const columns = data.data.prismicFooter?.data.columns?.filter((i): i is Queries.PrismicFooterDataColumns=> i != null)
      .map(i => {
        if(i.is_sourcecode) {
          return <div dangerouslySetInnerHTML={{__html: i.content?.text ?? ""}} />
        } else {
          return <PrismicRichText
            field={i.content?.richText}
            linkResolver={linkResolver} />
        }
      })
      ?? [];

  const event = data.data.prismicEvent?.data

  return <LangContext.Provider value={parseLanguage(data.pageContext.lang)}>
    <BasePage languageConfig={languageConfig} contactInformation={contactInformation} columns={columns}>
        {event ? <EventHeader
          {...event} /> : <p>Keine Eventinformationen verf&uuml;gbar</p>}
        {slices ? <SliceZone slices={slices} components={components} context={data.pageContext} /> : null}
    </BasePage>
  </LangContext.Provider>
}

export function Head(data: PageProps<Queries.Query, PageContext>) {
  const pageTitle = data.data.prismicEvent?.data.event_title
  return <>
    <html lang={data.pageContext.lang} />
    <title>{pageTitle ? `${pageTitle} - ` : ''} Pittlerwerke</title>
    <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
    <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
    <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
    <link rel="manifest" href="/site.webmanifest"></link>
  </>
}

export default withPrismicPreview(Page)

export const pageQuery = graphql`
  query EventByPrismicId($prismicId: ID!, $lang: String!) {
    allPrismicHomepage {
      edges {
        node {
          lang
        }
      }
    }
    prismicFooter(lang: {eq:$lang}) {
      _previewable
      data {
        contact_information {
          richText
        }
        columns {
          is_sourcecode
          content {
            richText
            text
          }
        }
      }
    }
    prismicEvent(prismicId: {eq: $prismicId}, lang: {eq: $lang}) {
      _previewable
      prismicId
      type
      alternate_languages {
        document {
          ... on PrismicEvent {
            type
            lang
            data {
              start_date,
              event_title,
            }
          }
        }
      }
      data {
        event_title
        banner {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
        description {
          richText
          text
        }
        start_date
        end_date
        entry_price
        ignore_event_time
        links {
          link {
            url
          }
          link_text
        }
        slices {
          ... on PrismicSliceType {
            slice_label
            slice_type
          }
          ... PrismicHeadlineWithCopy
          ... PrismicTextMitBild
          ... PrismicUeberschrift
          ... PrismicCallToAction
        }
      }
    }
  }`