import dayjs from "dayjs"
import { LANGUAGE } from "../../../helper/lang"


interface DateTranslation {
  until: string
  soon: string
}

const TRANSLATIONS: Record<LANGUAGE, DateTranslation> = {
  [LANGUAGE.ENGLISH]: {
    until: "until",
    soon: "soon"
  },
  [LANGUAGE.GERMAN]: {
    until: "bis",
    soon: "demnächst"
  }
}

export function renderDateString(lang: LANGUAGE, start_date?: dayjs.Dayjs, end_date?: dayjs.Dayjs, mode: 'short' | 'normal' = 'normal' ): string {
  const format = mode == 'normal' ? 'DD.MM.YYYY' : 'DD.MM.YY'
  if(start_date && end_date) {
    return renderStartEndDateString(start_date, end_date, format)
  } else if(start_date) {
    return `${start_date.format(format)}`
  } else if(end_date) {
    return `${TRANSLATIONS[lang].until} ${end_date.format(format)}`
  }

  return TRANSLATIONS[lang].soon
}

function renderStartEndDateString(start_date: dayjs.Dayjs, end_date: dayjs.Dayjs, format: string): string {
  if(start_date?.isSame(end_date, "day")) {
    return start_date.format(format)
  } else {
    return `${start_date.format(format)} — ${end_date.format(format)}`
  }
}