import { Language } from "@prismicio/types";
import dayjs, { Dayjs } from "dayjs";
import React, { useContext } from "react";
import { LangContext, LANGUAGE } from "../../../../helper/lang";
import { renderDateString } from "../util";
import * as styles from "./style.module.scss"

interface EventPromotionProps {
  title: JSX.Element
  subline: string
  eventTitle: string
  startDate?: dayjs.Dayjs
  endDate?: dayjs.Dayjs
  headerImage: JSX.Element
  description: JSX.Element
  eventUrl: string
}

interface EventPromotionTranslation {
    toEvent: string
}

const TRANSLATIONS: Record<LANGUAGE, EventPromotionTranslation> = {
  [LANGUAGE.ENGLISH]: {
    toEvent: "Go to Event"
  },
  [LANGUAGE.GERMAN]: {
    toEvent: "Zur Veranstaltung"
  }
}

const EventPromotion =(props: EventPromotionProps) => {
  const {
    title,
    subline,
    startDate,
    endDate,
    description,
    headerImage,
    eventTitle,
    eventUrl
  } = props

  const lang = useContext(LangContext)

  return <section
    className={styles.container}
  >
    <div className={styles.containerInner}>
      <div className={styles.preTitleContainer}>
        {title}
        <span className={styles.subline}>{subline}</span>
      </div>
      <div className={styles.headerImageContainer}>
        {headerImage}
      </div>
      <div className={styles.descriptionContainer}>
        <div className={styles.descriptionHeader}>
          <span className={styles.descriptionHeader__title}>{eventTitle}</span>
          <span className={styles.descriptionHeader__date}>{renderDateString(lang, startDate, endDate)}</span>
        </div>
        {description}
        <div className={styles.eventLink}>
          <a href={eventUrl}>{TRANSLATIONS[lang].toEvent}</a>
        </div>
      </div>
    </div>
  </section>
}

export default EventPromotion