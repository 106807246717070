import React from 'react'
import * as styles from "./style.module.scss";

interface CallToActionBaseProps {
  title: JSX.Element
  copy: JSX.Element
  buttonText: string
  buttonTarget: string
  textColor?: string
}

type CallToActionWithImage = CallToActionBaseProps & {
  backgroundImage: JSX.Element
  variant: "default"
}

type CallToActionWithColor = CallToActionBaseProps & {
  backgroundColor: string
  variant: "farbe"
}

type CallToActionProps = CallToActionWithColor | CallToActionWithImage

function hexToRgb(hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

function getContrastColor(hexColor: string) {
  const bgColor = hexToRgb(hexColor);
  if (!bgColor) {
    return 'black'; // Default color if hexColor is not valid
  }
  // Convert the background color from RGB to YIQ to determine whether the color is light or dark
  const yiq = ((bgColor.r * 299) + (bgColor.g * 587) + (bgColor.b * 114)) / 1000;
  return (yiq >= 128) ? 'black' : 'white';
}

const CallToAction = (props: CallToActionProps) => {
  const outerContentWrapperClass = [
    styles.outerContainer,
    props.variant == "default" ? styles.outerContainerWithBackgroundImage : styles.outerContainerWithBackgroundColor
  ].join(" ")

  return <div
          style={{
            "--bg-color": props.variant == "farbe" ? props.backgroundColor: undefined,
            "--text-color": props.textColor ?? "#fff",
            "--text-contrast-color": getContrastColor(props.textColor ?? "#fff"),
          } as any}
          className={outerContentWrapperClass}
        >
      <div className={styles.container}>
      {props.variant == "default" && props.backgroundImage}
      <div className={styles.contentWrapper}>
        <div className={styles.headerWrapper}>
          {props.title}
        </div>
        <div className={styles.copyWrapper}>
          {props.copy}
        </div>
        <div className={styles.buttonWrapper}>
          <a href={props.buttonTarget}>
            {props.buttonText}
          </a>
        </div>
      </div>
    </div>
  </div>
}

export default CallToAction