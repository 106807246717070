import * as React from 'react'
import {
  PrismicPreviewProvider,
  componentResolverFromMap,
} from 'gatsby-plugin-prismic-previews'

import PageTemplate from './src/templates/page'
import EventTemplate from './src/templates/event'
import { linkResolver } from './src/helper/helper'
import dayjs from "dayjs"
import timezone from "dayjs/plugin/timezone"

dayjs.extend(timezone)
dayjs.tz.setDefault('Europe/Berlin')


export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider
    repositoryConfigs={[
      {
        repositoryName: 'pittlerwerke',
        linkResolver,
        componentResolver: componentResolverFromMap({
          page: PageTemplate,
          event: EventTemplate,
          homepage: PageTemplate,
        }),
      },
    ]}
  >
    {element}
  </PrismicPreviewProvider>
)