// extracted by mini-css-extract-plugin
export var container = "style-module--container--3338a";
export var descriptionContainer = "style-module--descriptionContainer--f02a4";
export var eventHeading = "style-module--eventHeading--1eb22";
export var eventImage = "style-module--eventImage--8ba80";
export var eventMeta = "style-module--eventMeta--707d1";
export var eventMetaInner = "style-module--eventMetaInner--58f04";
export var eventMetaInner__row = "style-module--eventMetaInner__row--2e76e";
export var eventMetaProperty__header = "style-module--eventMetaProperty__header--a44ca";
export var eventMetaProperty__value = "style-module--eventMetaProperty__value--9ae0d";
export var eventMetaProperty__wrapper = "style-module--eventMetaProperty__wrapper--48a8b";
export var eventMeta__externalLinks = "style-module--eventMeta__externalLinks--51a1e";
export var eventWrapper = "style-module--eventWrapper--f6082";