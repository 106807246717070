import React from 'react';
import { SliceComponentProps } from '@prismicio/react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import {default as ImageComponent} from '../../components/slices/Image'

const Image = (data: SliceComponentProps<Queries.PrismicHeroImage>) => {
  const image = data.slice.primary.image?.localFile?.childImageSharp?.gatsbyImageData

  return <ImageComponent
    image={image ? <GatsbyImage image={image} alt="" /> : <img src="" />}
  />
}

export default Image;

export const fragment = graphql`
fragment PrismicImage on PrismicImage {
  ... on PrismicImageDefault {
    primary {
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
            original {
              src
            }
          }
        }
      }
    }
  }
}
`
