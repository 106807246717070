import React, { useContext } from 'react'
import { PrismicRichText, SliceComponentProps } from '@prismicio/react'
import { graphql } from 'gatsby';
import * as styles from './style.module.scss'
import { linkResolver } from '../../../helper/helper';
import { GatsbyImage } from 'gatsby-plugin-image';
import dayjs from 'dayjs';
import { LangContext, LANGUAGE } from '../../../helper/lang';


interface EventMetaProps {
  start_date?: string
  end_date?: string
  entry_price?: number
  ignore_event_time: boolean
  links: {text:string, url:string}[]
}

interface EventMetaTranslation {
    until: string
    soon: string
    date: string
    start: string
    end: string
    entry: string
}

const TRANSLATIONS: Record<LANGUAGE, EventMetaTranslation> = {
  [LANGUAGE.ENGLISH]: {
    until: "until",
    soon: "soon",
    date: "Date",
    end: "End",
    entry: "Entry",
    start: "Start"

  },
  [LANGUAGE.GERMAN]: {
    until: "bis",
    soon: "demnächst",
    date: "Datum",
    end: "Ende",
    entry: "Eintritt",
    start: "Start"
  }
}


function renderDateString(lang: LANGUAGE, start_date?: dayjs.Dayjs, end_date?: dayjs.Dayjs): string {
  if(start_date && end_date) {
    return `${start_date.format('DD.MM.YYYY')} — ${end_date.format('DD.MM.YYYY')}`
  } else if(start_date) {
    return `${start_date.format('DD.MM.YYYY')}`
  } else if(end_date) {
    return `${TRANSLATIONS[lang].until} ${end_date.format('DD.MM.YYYY')}`
  }

  return TRANSLATIONS[lang].soon
}

const EventMeta = (data: EventMetaProps) => {
  const {
    start_date,
    end_date,
    links,
    ignore_event_time,
    entry_price
  } = data

  const lang = useContext(LangContext)

  return (
      <div className={styles.eventMeta}>
        <div className={styles.eventMetaInner}>
          {start_date != null ? <div className={styles.eventMetaInner__row}>
            <div className={styles.eventMetaProperty__wrapper}>
              <span className={styles.eventMetaProperty__header}>
                {TRANSLATIONS[lang].date}
              </span>
              <span className={styles.eventMetaProperty__value}>
                {renderDateString(lang, start_date ? dayjs(start_date) : undefined, end_date ? dayjs(end_date) : undefined)}
              </span>
            </div>
          </div>: null}
          {start_date && !ignore_event_time && <div className={styles.eventMetaInner__row}>
            <div className={styles.eventMetaProperty__wrapper}>
              <span className={styles.eventMetaProperty__header}>
                {TRANSLATIONS[lang].start}
              </span>
              <span className={styles.eventMetaProperty__value}>
                {dayjs(start_date).format('HH:mm')}
              </span>
            </div>
            {end_date && !ignore_event_time && <div className={styles.eventMetaProperty__wrapper}>
              <span className={styles.eventMetaProperty__header}>
                {TRANSLATIONS[lang].end}
              </span>
              <span className={styles.eventMetaProperty__value}>
                {dayjs(end_date).format('HH:mm')}
              </span>
            </div>}
          </div>}
          {(entry_price != undefined) && <>
            <div className={styles.eventMetaProperty__wrapper}>
              <span className={styles.eventMetaProperty__header}>
                {TRANSLATIONS[lang].entry}
              </span>
              <span className={styles.eventMetaProperty__value}>
                {entry_price > 0 ? `${entry_price} €` : 'Frei'}
              </span>
            </div>
          </>}
        </div>
        <div className={styles.eventMeta__externalLinks}>
          {links.map(i =>
            <a key={i.url} target="_blank" href={i.url}>{i.text}</a>
          )}
        </div>
      </div>
  )
}

const EventHeader = (data: Queries.PrismicEventDataType) => {
  const {
    event_title,
    description,
    banner,
    start_date,
    end_date,
    links,
    entry_price,
    ignore_event_time
  } = data

  const bannerLocalImage = banner?.localFile?.childImageSharp?.gatsbyImageData

  return <section className={styles.container}>
      <div className={styles.eventHeading}>
        <h1>{event_title}</h1>
      </div>
      {(banner && bannerLocalImage)
      ? <GatsbyImage
          className={styles.eventImage}
          image={bannerLocalImage}
          alt={banner.alt ?? "Event header"}
        /> : null}
      <div className={styles.descriptionContainer}>
        {description
          ? <PrismicRichText
            field={description.richText}
            linkResolver={linkResolver}
          />
          : null
        }
      </div>
      <EventMeta
        start_date={start_date ?? undefined}
        end_date={end_date ?? undefined}
        ignore_event_time={ignore_event_time ?? true}
        entry_price={entry_price ?? undefined}
        links={links
          ?.map(i => ({text: i?.link_text , url: i?.link?.url }))
          .filter((i): i is {text: string, url: string} => i.text != null && i.url != null)
          ?? []
        }
      />
  </section>
}

export default EventHeader

