import React from "react"
import * as styles from "./style.module.scss"


interface HeroImageProps {
  backgroundImage: JSX.Element
}

const HeroImage = (props: HeroImageProps) => {
  return <div className={styles.outerContainer}>
    <div className={styles.imageContainer}>
      {props.backgroundImage}
    </div>
  </div>
}

export default HeroImage