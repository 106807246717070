import { FilledContentRelationshipField } from "@prismicio/types"
import dayjs from "dayjs"
import slugify from "slugify"

export function isHomepage(data: any | null): data is Queries.PrismicHomepage {
  return data?.type === "homepage"
}

export function isPage(data: any | null): data is Queries.PrismicPage {
  return data?.type === "page"
}


const urlLangPrefixMap: Record<string, string> = {
  "de-de": "",
  "en-us": "/en"
}


export const getLangPrefix = (lang: string): string => {
  return urlLangPrefixMap[lang] ?? ""
}

const langDisplayString: Record<string, string> = {
  "de-de": "DE",
  "en-us": "EN"
}

export const getLangDisplayString = (lang: string): string => {
  return langDisplayString[lang] ?? lang
}

export const linkResolver = (doc: Queries.PrismicHomepage | Queries.PrismicPage | Queries.PrismicEvent, ) => {
  switch(doc.type) {
    case "homepage":
      return `${getLangPrefix(doc.lang)}/`
    case "event": {
      if(doc.data) {
        return getEventUrl(doc as Queries.PrismicEvent)
      }
    }
    case "page":
      return `${getLangPrefix(doc.lang)}/${(doc as Queries.PrismicPage).uid}/`
    default:
      return `${getLangPrefix(doc.lang)}/${doc.id}/`
  }
}

export function getEventUrl(event: Queries.PrismicEvent): string {

  const {
    start_date,
    event_title
  } = event.data

  const date = dayjs(start_date);
  const slug = event_title ? slugify(event_title) : null;
  const hash = hashStrings([
    date.year().toString(),
    date.month().toString(),
    date.day().toString(),
    slug ?? event.prismicId
  ])

  return `${getLangPrefix(event.lang)}/events/${date.year()}/${date.month()}/${date.day()}/${hash}${slug ? `-${slug}` : ""}`
}

function hashStrings(strings: string[]): string {
  const concatenatedString = strings.join('');
  let hash = 0;
  for (let i = 0; i < concatenatedString.length; i++) {
    const charCode = concatenatedString.charCodeAt(i);
    hash = ((hash << 5) + hash) + charCode;
    hash = hash >>> 0; // convert hash to unsigned integer
  }
  return hash.toString(16);
}