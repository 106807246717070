import React from 'react'
import { PrismicRichText, SliceComponentProps } from '@prismicio/react'
import { graphql } from 'gatsby'

const ExternalCode = ({ slice }: SliceComponentProps<Queries.PrismicExternalCode>) => {
  return <section>
    {slice.primary.code &&
      <div dangerouslySetInnerHTML={{__html: slice.primary.code.text ?? ""}} />
    }
  </section>
}

export default ExternalCode

export const query = graphql`
fragment PrismicExternalCode on PrismicExternalCode {
	... on PrismicExternalCodeDefault {
    primary {
      code {
        text
        richText
      }
    }
  }
}
`