import React from "react";

export enum LANGUAGE {
  GERMAN = 'de-de',
  ENGLISH = 'en-us'
}

export function parseLanguage(lang: string): LANGUAGE {
  switch(lang) {
    case "en-us": return LANGUAGE.ENGLISH
    case "de-de": return LANGUAGE.GERMAN
    default: return LANGUAGE.GERMAN
  }
}


export const LangContext = React.createContext(LANGUAGE.GERMAN)
