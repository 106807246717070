import React from "react"
import {PageProps, graphql} from "gatsby"
import { PrismicRichText, SliceZone } from "@prismicio/react"
import {components} from "../slices"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { getLangDisplayString, getLangPrefix, linkResolver } from "../helper/helper"
import { BasePage } from "./BasePage"
import { LangContext, parseLanguage } from "../helper/lang"

export interface PageContext {
  uid: string
  lang: string
  type: 'homepage' | 'page'
}

export interface LanguageConfig {
  displayString: string,
  urlPrefix: string
  isActive: boolean
}

function getPageInfo(data: Queries.Query, context: PageContext) {
  if(data.prismicHomepage != undefined && data.prismicPage == undefined) {
    return data.prismicHomepage
  } else if (data.prismicPage != undefined) {
    return data.prismicPage
  }
}

function Page(data: PageProps<Queries.Query, PageContext>) {
  const pageInfo = getPageInfo(data.data, data.pageContext)
  const slices = pageInfo ? pageInfo.data.slices : null

  const alternateLanguagePages = pageInfo?.alternate_languages.map(i => i.document).filter((i): i is Queries.PrismicPage=> i?.type == "page").reduce((prev, curr) => {
    return {
      ...prev,
      [curr.lang]: linkResolver(curr)
    }
  }, {} as Record<string, string>) ?? {}

  const languageConfig: Record<string, LanguageConfig> = [...new Set(data.data.allPrismicHomepage.edges.map(i => i.node.lang))].reduce((prev, curr) => {
    return {
      ...prev,
      [curr]: {
        displayString: getLangDisplayString(curr),
        urlPrefix: alternateLanguagePages[curr] ?? getLangPrefix(curr),
        isActive: curr == data.pageContext.lang
      }
    }
  }, {} as Record<string, LanguageConfig>)

  const contactInformation = data.data.prismicFooter?.data.contact_information;

  const columns = data.data.prismicFooter?.data.columns?.filter((i): i is Queries.PrismicFooterDataColumns=> i != null)
      .map(i => {
        if(i.is_sourcecode) {
          return <div dangerouslySetInnerHTML={{__html: i.content?.text ?? ""}} />
        } else {
          return <PrismicRichText
            field={i.content?.richText}
            linkResolver={linkResolver} />
        }
      })
      ?? [];

  return <LangContext.Provider value={parseLanguage(data.pageContext.lang)}>
      <BasePage languageConfig={languageConfig} columns={columns} contactInformation={contactInformation}>
        {slices ? <SliceZone slices={slices} components={components} context={data.pageContext} /> : null}
      </BasePage>
    </LangContext.Provider>
}

export function Head(data: PageProps<Queries.Query, PageContext>) {
  const pageTitle = data.data.prismicPage?.data.page_title
  return <>
    <html lang={data.pageContext.lang} />
    <title>{pageTitle ? `${pageTitle} - ` : ''} Pittlerwerke</title>
    <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
    <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
    <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
    <link rel="manifest" href="/site.webmanifest"></link>
  </>
}

export default withPrismicPreview(Page)

export const pageQuery = graphql`
  query PageByUid($uid: String!, $lang: String!) {
    allPrismicHomepage {
      edges {
        node {
          lang
        }
      }
    }
    prismicFooter(lang: {eq:$lang}) {
      _previewable
      data {
        columns {
          is_sourcecode
          content {
            richText
            text
          }
        }
        contact_information {
          richText
        }
      }
    }
    prismicHomepage(lang: {eq: $lang}) {
      _previewable
      type
      alternate_languages {
        lang
      }
      data {
        page_title
        slices {
          ... on PrismicSliceType {
            slice_label
            slice_type
          }
          ... PrismicHeroImage
          ... PrismicHeadlineWithCopy
          ... PrismicGrid
          ... PrismicEventPromotion
          ... PrismicExternalCode
        }
      }
    }
    prismicPage(uid: {eq: $uid}, lang: {eq: $lang}) {
      _previewable
      uid
      type
      alternate_languages {
        lang
        document {
          ... on PrismicPage {
            uid
            type
            lang
            data {
              page_title
            }
          }
        }
      }
      data {
        page_title
        slices {
          ... on PrismicSliceType {
            slice_label
            slice_type
          }
          ... PrismicHeroImage
          ... PrismicHeadlineWithCopy
          ... PrismicGrid
          ... PrismicTextMitBild
          ... PrismicUeberschrift
          ... PrismicEventPromotion
          ... PrismicExternalCode
          ... PrismicCallToAction
          ... PrismicImage
          ... PrismicEventList
        }
      }
    }
  }`