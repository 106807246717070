import React from 'react'
import { PrismicRichText, SliceComponentProps } from '@prismicio/react'
import { graphql } from 'gatsby'

import { Heading } from '../../components/slices/Headline'

const Ueberschrift = ({ slice } : SliceComponentProps<Queries.PrismicUeberschrift>) => {
  return (
  slice.primary.headline?.richText?.[0] &&
    <Heading
      headline={<PrismicRichText field={slice.primary.headline?.richText} />}
      subline={slice.primary.subline}
    />
  )
}

export default Ueberschrift

export const fragment = graphql`
fragment PrismicUeberschrift on PrismicUeberschrift {
	... on PrismicUeberschriftDefault {
    primary {
      headline {
        text
        html
        richText
      }
      subline
    }
  }
}
`