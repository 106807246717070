import React, { PropsWithChildren, useContext } from "react";
import Layout from "../components/layout";
import { PrismicRichText } from "@prismicio/react";
import { linkResolver } from "../helper/helper";
import Footer from "../components/footer/footer";
import { YellowBar } from "../components/util/YellowBar";
import * as styles from "./base-page.module.scss";
import { LanguageConfig } from "./page";
import { LangContext } from "../helper/lang";

export function BasePage(
  props: PropsWithChildren<{
    languageConfig: Record<string, LanguageConfig>
    contactInformation: Queries.Maybe<Queries.PrismicStructuredTextType> | undefined;
    columns: JSX.Element[];
  }>) {
  const { contactInformation, columns, languageConfig } = props;
  return <div className={styles.pageWrapper}>
    <YellowBar />
    <Layout languageConfig={languageConfig}>
      {props.children}
    </Layout>
    <Footer
      contactInformation={contactInformation
        ? <PrismicRichText
          field={contactInformation.richText}
          linkResolver={linkResolver} />
        : null}
      columns={columns} />
  </div>;
}
