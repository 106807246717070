import React, { PropsWithChildren, useContext } from "react"
import { LangContext } from "../helper/lang"
import '../styles/layout.scss'
import { LanguageConfig } from "../templates/page"
import Navigation from "./navigation/navigation"


const Layout: React.FC<PropsWithChildren & {
  languageConfig: Record<string, LanguageConfig>
}> = ({languageConfig, children}) => {


  return <>
    <Navigation
    languageConfig={languageConfig}
    />
    <main style={{flex: '1 0 auto'}}>{children}</main>
  </>
}

export default Layout
