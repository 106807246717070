// extracted by mini-css-extract-plugin
export var dateContainer = "event_item-module--dateContainer--18fb2";
export var dateContainerSplit = "event_item-module--dateContainer--split--0977d";
export var dateContainer__day = "event_item-module--dateContainer__day--9d3bb";
export var dateContainer__month = "event_item-module--dateContainer__month--e0596";
export var eventItemContainer = "event_item-module--eventItemContainer--b0923";
export var eventItemContainer__dateContainer = "event_item-module--eventItemContainer__dateContainer--f1429";
export var eventItemContainer__metaContainer = "event_item-module--eventItemContainer__metaContainer--ea62b";
export var metaContainer__title = "event_item-module--metaContainer__title--6efe4";
export var metaInfoContainer = "event_item-module--metaInfoContainer--ad2c5";
export var metaInfoContainer__heading = "event_item-module--metaInfoContainer__heading--4b1e9";
export var metaInfoContainer__value = "event_item-module--metaInfoContainer__value--1c594";
export var metaInfoWrapper = "event_item-module--metaInfoWrapper--3110f";
export var metaInfoWrapper__dateWrapper = "event_item-module--metaInfoWrapper__dateWrapper--ab0d2";