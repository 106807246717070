import React from 'react'
import { PrismicRichText, SliceComponentProps } from '@prismicio/react'
import {EventList as EventListComponent} from '../../components/slices/events/EventList'
import { EventListSlice } from '../../../.slicemachine/prismicio'
import { PageContext } from '../../templates/page'
import { graphql } from 'gatsby'

/**
 * @typedef {import("@prismicio/client").Content.EventListSlice} EventListSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<EventListSlice>} EventListProps
 * @param { EventListProps }
 *
 * **/
const EventList = (args: SliceComponentProps<Queries.PrismicEventListDefault, PageContext>) => {
  return <EventListComponent
    language={args.context.lang}
    excludeTags={args.slice.primary.exclude_tags?.split(",").map(i => i.trim())}
    includeTags={args.slice.primary.include_tags?.split(",").map(i => i.trim())}
  />
}

export default EventList

export const fragment = graphql`
fragment PrismicEventList on PrismicEventList {
  ... on PrismicEventListDefault {
    variation
    primary {
      exclude_tags,
      include_tags
    }
  }
}
`