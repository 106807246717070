import React from "react"
import * as styles from './yellow-bar.module.scss'


export const YellowBar = () => {
  return (
    <div className={styles.barGridWrapper}>
      <div className={styles.barGrid}>
        <div className={styles.bar} />
      </div>
    </div>
  )
}