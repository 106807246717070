module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"pittlerwerke","accessToken":"MC5ZOVdoUkJBQUFDTUFrSmZL.EA4C77-9Emrvv73vv70Z77-977-9Fe-_ve-_vWPvv73vv70c77-9FHzvv71j77-977-9Zi3vv73vv70j77-977-9","promptForAccessToken":true,"apiEndpoint":"https://pittlerwerke.cdn.prismic.io/api/v2","lang":"*","pageSize":100,"imageImgixParams":{"auto":"compress,format","fit":"max"},"imagePlaceholderImgixParams":{"w":100,"blur":15},"toolbar":"new"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
