import React from 'react';
import { SliceComponentProps } from '@prismicio/react';
import { graphql } from 'gatsby';
import {default as HeroImageComponent} from "../../components/slices/HeroImage"
import { GatsbyImage } from 'gatsby-plugin-image';

const HeroImage = (data: SliceComponentProps<Queries.PrismicHeroImage>) => {
  const image = data.slice.primary.image?.localFile?.childImageSharp?.gatsbyImageData

  return (
    <>
    <HeroImageComponent
      backgroundImage={image ? <GatsbyImage image={image} alt="" /> : <></>}
    />
    </>
  )
}

export default HeroImage;

export const fragment = graphql`
fragment PrismicHeroImage on PrismicHeroImage {
  ... on PrismicHeroImageDefault {
    primary {
      image {
        alt
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
            original {
              src
            }
          }
        }
      }
    }
  }
}
`
