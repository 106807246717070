// extracted by mini-css-extract-plugin
export var active = "navigation-module--active--f4655";
export var desktopMenu = "navigation-module--desktopMenu--bb745";
export var langMenu = "navigation-module--langMenu--7fc83";
export var langMenu__item = "navigation-module--langMenu__item--bed7f";
export var langMenu__itemActive = "navigation-module--langMenu__item--active--22969";
export var logo = "navigation-module--logo--076cf";
export var mainMenu = "navigation-module--main-menu--07c41";
export var mainMenuItem = "navigation-module--main-menu--item--b6154";
export var mainNav = "navigation-module--main-nav--f8111";
export var menuToggle = "navigation-module--menu-toggle--51233";
export var menuToggleBar = "navigation-module--menu-toggle-bar--da6ba";
export var menuToggleBarBottom = "navigation-module--menu-toggle-bar--bottom--0d3a5";
export var menuToggleBarMiddle = "navigation-module--menu-toggle-bar--middle--8526c";
export var menuToggleBarTop = "navigation-module--menu-toggle-bar--top--0f406";
export var mobileMenu = "navigation-module--mobileMenu--eaab7";
export var mobileMenuList = "navigation-module--mobile-menu-list--d306f";
export var mobileMenuListOpen = "navigation-module--mobile-menu-list--open--c28e0";
export var navOpen = "navigation-module--nav-open--cbc70";