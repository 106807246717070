
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React, { PropsWithChildren } from 'react'
import * as styles from "./style.module.scss";

export const GridWrapper = (props: PropsWithChildren<{numberOfColumns: number | null}>) => {
  return <div className={styles.gridWrapperContainer} style={{'--columnSize': 12 / (props.numberOfColumns || 6)} as any}>
    {props.children}
  </div>
}

interface GridItemProps {
  title: string
  backgroundImage?: IGatsbyImageData
  backgroundAltText?: string
  target: string
}

export const GridItem = (props: GridItemProps) => {
  return <a href={props.target} className={styles.gridItemContainer}>
    <div className={styles.gridItemContainer__inner}>
      <h1>{props.title}</h1>
    </div>
    {props.backgroundImage ? <GatsbyImage
      image={props.backgroundImage}
      alt={props.backgroundAltText ?? "no alt text provided"}
      className={styles.gridItemBackgroundImage}
    /> : <img src='' className={styles.gridItemBackgroundImage} />}
  </a>
}

export const GridItemWithText = (props: GridItemProps & {description: JSX.Element}) => {
  return <div className={styles.gridItemWithTextContainer}>

    {props.backgroundImage ? <GatsbyImage
      image={props.backgroundImage}
      alt={props.backgroundAltText ?? "no alt text provided"}
      className={styles.gridItemBackgroundImage}
    /> : <img src='' className={styles.gridItemBackgroundImage} />}
    <div className={styles.gridItemWithTextContainer__inner}>
      <h2>{props.title}</h2>
      {props.description}
    </div>
  </div>
}