import React from 'react'
import { PrismicRichText, SliceComponentProps } from '@prismicio/react'
import { graphql } from 'gatsby'
import {default as EventPromotionCompnent} from "../../components/slices/events/EventPromotion"
import { GatsbyImage } from 'gatsby-plugin-image'
import { getEventUrl } from '../../helper/helper'
import dayjs from 'dayjs'
import EventPromotionList from '../../components/slices/events/EventPromotionList'
import { Event } from '../../components/slices/events/model'
import EventPromotionListHeader from '../../components/slices/events/EventPromotionHeader'


function sliceIsDefault(slice: any): slice is Queries.PrismicEventPromotionDefault {
  return slice.variation == 'default'
}

function sliceIsList(slice: any): slice is Queries.PrismicEventPromotionEventPromotionList {
  return slice.variation == 'eventPromotionList'
}

function sliceIsHeader(slice: any): slice is Queries.PrismicEventPromotionEventPromotionListHeader {
  return slice.variation == 'eventPromotionListHeader'
}

const EventPromotion = ({ slice }: SliceComponentProps<Queries.PrismicEventPromotion>) => {
  if(sliceIsDefault(slice)) {
    const event = slice.primary.event?.document?.data
    const subline = slice.primary.subline
    if(event == undefined) {
      return <EventPromotionCompnent
      title={
            slice.primary.title ?
            <PrismicRichText field={slice.primary.title.richText}/>
            : <h2>Template slice, update me!</h2>
      }
      subline={subline || ""}
      description={
        slice.primary.description ?
        <PrismicRichText field={slice.primary.description.richText}/>
        : <p>start by editing this slice from inside Slice Machine!</p>
      }
      headerImage={<></>}
      startDate={undefined}
      endDate={undefined}
      eventTitle={""}
      eventUrl={""}
    />

    } else {
      const {
        banner,
        start_date,
        end_date,
        event_title
      } = (event as Queries.PrismicEventDataType)
      const bannerData = banner?.localFile?.childImageSharp

      return <EventPromotionCompnent
        title={
              slice.primary.title ?
              <PrismicRichText field={slice.primary.title.richText}/>
              : <></>
        }
        subline={subline || ""}
        description={
          slice.primary.description ?
          <PrismicRichText field={slice.primary.description.richText}/>
          : <p></p>
        }
        headerImage={bannerData ? <GatsbyImage
          image={bannerData.gatsbyImageData}
          alt={banner?.alt ?? ""}
        /> : <img src="" />}
        startDate={start_date ? dayjs(start_date) : undefined}
        endDate={end_date ? dayjs(end_date) : undefined}
        eventTitle={event_title ?? ""}
        eventUrl={getEventUrl(slice.primary.event?.document as Queries.PrismicEvent)}
      />
    }
  } else if(sliceIsHeader(slice)) {

    const events: Event[] = slice
      .items
      .map<Event | null>(i => {
        const event = i.event?.document as Queries.PrismicEvent
        const tags: string[] = i.event?.tags?.filter((i): i is string => i != null || i != undefined) ?? []

        if(!event) {
          return null
        }

        const thumbnail = event.data.banner?.thumbnails?.square?.localFile?.childImageSharp?.gatsbyImageData
        const image = event.data.banner?.localFile?.childImageSharp?.gatsbyImageData

        return {
            description: <PrismicRichText field={event.data.short_description?.richText} />,
          eventTitle: event.data.event_title || "",
          eventUrl: getEventUrl(event),
          headerImage: image ? <GatsbyImage
            image={image}
            alt={event.data.banner?.alt ?? ""}
          /> : <img src="" />,
          thumbnail: thumbnail ? <GatsbyImage
            image={thumbnail}
            alt={event.data.banner?.thumbnails?.square?.alt ?? ""}
          /> : undefined,
          startDate: event.data.start_date ? dayjs(event.data.start_date) : undefined,
          endDate: event.data.end_date ? dayjs(event.data.end_date) : undefined,
          ignoreEventTime: !!event.data.ignore_event_time,
          tags: tags
        }
      }).filter((i): i is Event => i != null)

    return <EventPromotionListHeader events={events} />

  } else if(sliceIsList(slice)) {

    const events: Event[] = (slice as Queries.PrismicEventPromotionEventPromotionList)
      .items
      .map<Event | null>(i => {
        const event = i.event?.document as Queries.PrismicEvent
        const tags: string[] = i.event?.tags?.filter((i): i is string => i != null || i != undefined) ?? []

        if(!event) {
          return null
        }

        const image = event.data.banner?.localFile?.childImageSharp?.gatsbyImageData

        return {
            description: <PrismicRichText field={i.description?.richText} />,
          eventTitle: event.data.event_title || "",
          eventUrl: getEventUrl(event),
          headerImage: image ? <GatsbyImage
            image={image}
            alt={event.data.banner?.alt ?? ""}
          /> : <img src="" />,
          startDate: event.data.start_date ? dayjs(event.data.start_date) : undefined,
          endDate: event.data.end_date ? dayjs(event.data.end_date) : undefined,
          ignoreEventTime: !!event.data.ignore_event_time,
          tags: tags
        }
      }).filter((i): i is Event => i != null)

    return <EventPromotionList
      events={events}
    />
  }

  return null
}

export default EventPromotion

export const fragment = graphql`
fragment PrismicEventPromotion on PrismicEventPromotion {
  ... on PrismicEventPromotionEventPromotionList {
    variation
    items {
      description {
        richText
      }
      event {
        document {
          ... on PrismicEvent {
            lang
            data {
              banner {
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                  }
                }
              }
              event_title
              start_date
              end_date
            }
          }
        }
      }
    }
  }
  ... on PrismicEventPromotionEventPromotionListHeader {
    variation
    items {
      event {
        document {
          ... on PrismicEvent {
            lang
            data {
              short_description {
                richText
              }
              banner {
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED, width:790, height: 480)
                  }
                }
                thumbnails {
                  square {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(layout: CONSTRAINED, width:240, height: 240)
                      }
                    }
                  }
                }
              }
              event_title
              start_date
              end_date
            }
          }
        }
      }
    }
  }
  ... on PrismicEventPromotionDefault {
    variation
    primary {
      title {
        richText
      }
      subline
      description {
        richText
      }
      event {
        url
        document {
          ... on PrismicEvent {
            lang
            data {
              banner {
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                  }
                }
              }
              event_title
              start_date
              end_date
            }
          }
        }
      }
    }
  }
}
`