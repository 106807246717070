import React from "react"
import * as styles from "./style.module.scss"

interface TextWithImageProps {
  text: JSX.Element
  image?: JSX.Element
  variant: string
}

export const TextWithImage = ({text, image, variant}: TextWithImageProps) => {
  return <div className={[styles.container, styles[`container__${variant}`]].join(" ")}>
    <div className={styles.textWrapper}>
      {text}
    </div>
    <div className={styles.imageWrapper}>
      {image}
    </div>
  </div>
}