import React from 'react'
import { PrismicRichText, SliceComponentProps } from '@prismicio/react'
import {default as CallToActionCompnent} from "../../components/slices/CallToAction"
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'


function isDefault(val: any): val is Queries.PrismicCallToActionDefaultPrimary {
  return val.background_image != null
}

/**
 * @typedef {import("@prismicio/client").Content.CallToActionSlice} CallToActionSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<CallToActionSlice>} CallToActionProps
 * @param { CallToActionProps }
 */
const CallToAction = ({ slice }: SliceComponentProps<Queries.PrismicCallToAction>) => {
  const primary = slice.primary
  return <CallToActionCompnent
    title={slice.primary.title ? <PrismicRichText field={slice.primary.title.richText} /> : <h2>Template slice, update me!</h2>}
    copy={slice.primary.text ? <PrismicRichText field={slice.primary.text.richText} /> : <p>Template slice, update me!</p>}
    textColor={slice.primary.text_color || undefined}
    buttonText={slice.primary.button_text ?? "Go"}
    buttonTarget={slice.primary.button_target?.url ?? "#"}
    {...(isDefault(primary) ? {
      variant: 'default',
      backgroundImage: primary.background_image?.localFile?.childImageSharp ? <GatsbyImage
          image={primary.background_image!!.localFile!!.childImageSharp!!.gatsbyImageData}
          alt={primary.background_image?.alt ?? ""}
        /> : <img src="" />
    }: {
      variant: 'farbe',
      backgroundColor: primary.background_color ?? ""
    })}

  />
}

export default CallToAction

export const fragment = graphql`
fragment PrismicCallToAction on PrismicCallToAction {
 ... on PrismicCallToActionDefault {
  	primary {
      text {
        richText
      }
      title {
        richText
      }
      background_image {
        localFile {
          childImageSharp {
            gatsbyImageData(layout:CONSTRAINED)
          }
        }
      }
      button_text
      text_color
      button_target {
        link_type
        url
        target
        type

      }
    }
  }
  ... on PrismicCallToActionFarbe {
    primary {
      text {
        richText
      }
      title {
        richText
      }
      background_color
      button_text
      text_color
      button_target {
        link_type
        url
        target
        type
      }
    }
  }
}

`