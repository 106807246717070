import dayjs, { Dayjs } from "dayjs";
import React, { useContext } from "react";
import * as styles from "./style.module.scss"
import { Event } from "../model"
import { renderDateString } from "../util";
import { LangContext, LANGUAGE } from "../../../../helper/lang";


interface EventPromotionProps {
  events: Event[]
}

interface EventPromotionListItemTranslation {
    toEvent: string
}

const TRANSLATIONS: Record<LANGUAGE, EventPromotionListItemTranslation> = {
  [LANGUAGE.ENGLISH]: {
    toEvent: "Go to Event"
  },
  [LANGUAGE.GERMAN]: {
    toEvent: "Zur Veranstaltung"
  }
}


const EventPromotionListItem = (props: { event: Event }) => {
  const {
    description,
    eventTitle,
    eventUrl,
    headerImage,
    endDate,
    startDate
  } = props.event

  const lang = useContext(LangContext)

  return <div className={styles.eventItem}>
    <div className={styles.eventItem__title}>
      {eventTitle}
    </div>
    {headerImage}
    <div className={styles.eventItem__metaWrapper}>
      <div className={styles.eventItem__date}>
        {renderDateString(lang, startDate, endDate)}
      </div>
      <div className={styles.eventItem__description}>
        {description}
      </div>
      <a href={eventUrl} className={styles.eventItem__link}>{TRANSLATIONS[lang].toEvent}</a>
    </div>
  </div>
}

const EventPromotionList = (props: EventPromotionProps) => {
  const {
    events
  } = props

  return <section
    className={styles.container}
  >
    {events.map(i => <EventPromotionListItem key={i.eventUrl} event={i} />)}
  </section>
}

export default EventPromotionList