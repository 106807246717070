import React from 'react'
import { PrismicRichText, SliceComponentProps } from '@prismicio/react'
import { graphql } from 'gatsby'
import { linkResolver } from "../../helper/helper"
import {default as HeadlineWithCopyComponent } from "../../components/slices/Fliesstext"

const HeadlineWithCopy = ({ slice }: SliceComponentProps<Queries.PrismicHeadlineWithCopy>) => {
  const variant = slice.variation == "default" ? "v1" : "v2";
  return <HeadlineWithCopyComponent
    title={
          slice.primary.title ?
          <PrismicRichText field={slice.primary.title.richText} linkResolver={linkResolver}/>
          : <h2>Template slice, update me!</h2>
    }
    copy={
        slice.primary.copy ?
        <PrismicRichText field={slice.primary.copy.richText} linkResolver={linkResolver}/>
        : <p>start by editing this slice from inside Slice Machine!</p>
    }
    variant={variant}
  />
}

export default HeadlineWithCopy

export const fragment = graphql`
fragment PrismicHeadlineWithCopy on PrismicHeadlineWithCopy {
  ... on PrismicHeadlineWithCopyDefault {
    variation
    primary {
      copy {
        richText
      }
      title {
        richText
      }
    }
  }
  ... on PrismicHeadlineWithCopyCopySpalten {
    variation
    primary {
      copy {
        richText
      }
      title {
        richText
      }
    }
  }
}
`