import React from 'react'
import { PrismicRichText, SliceComponentProps } from '@prismicio/react'
import { graphql } from 'gatsby'
import { TextWithImage } from '../../components/slices/TextWithImage'
import { GatsbyImage } from 'gatsby-plugin-image'

/**
 * @typedef {import("@prismicio/client").Content.TextMitBildSlice} TextMitBildSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<TextMitBildSlice>} TextMitBildProps
 * @param { TextMitBildProps }
 */
const TextMitBild = ({slice}: SliceComponentProps<Queries.PrismicTextMitBild>) => {
  const image = slice.primary?.image?.localFile?.childImageSharp?.gatsbyImageData
  return <TextWithImage
    text={slice.primary?.text ? <PrismicRichText field={slice.primary?.text?.richText} /> : <></>}
    image={image
      ? <GatsbyImage image={image} alt="" />
      : undefined
    }
    variant={slice.variation}
  />
}

export default TextMitBild

export const fragment = graphql`
  fragment PrismicTextMitBild on PrismicTextMitBild {
    ... on PrismicTextMitBildDefault {
      variation
      primary {
        text {
          richText
        }
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
              original {
                src
              }
            }
          }
        }
      }
    }
    ... on PrismicTextMitBildBildLinks {
      variation
      primary {
        text {
          richText
        }
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
              original {
                src
              }
            }
          }
        }
      }
    }
    ... on PrismicTextMitBildBildUnterText {
      variation
      primary {
        text {
          richText
        }
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
              original {
                src
              }
            }
          }
        }
      }
    }
  }
`