import React, { PropsWithChildren } from "react"
import * as styles from "./style.module.scss"

interface HeadingProps {
  headline: JSX.Element
  subline: string | null
}

export const Heading = (props: HeadingProps) => {
  return <header className={styles.header}>
    {props.headline}
    <p>{props.subline}</p>
  </header>
}