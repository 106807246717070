import { PrismicRichText } from "@prismicio/react";
import dayjs from "dayjs";
import React, { SyntheticEvent, UIEventHandler, useEffect, useRef, useState } from "react"
import { Event, RawEvent } from "../model"
import { EventItem } from "./EventItem";
import * as styles from "./style.module.scss"


async function fetchEvents(url: string, language: string): Promise<Event[]> {
  const data: RawEvent[] = await (await fetch(url)).json();

  const events: Event[] = data
  .filter(i => i.language == language)
  .map(i => ({
    description: typeof i.description == "string" ? <></>: <PrismicRichText field={i.description as any} />,
    eventTitle: i.eventTitle,
    eventUrl: i.eventUrl,
    headerImage: <></>,
    endDate: i.endDate ? dayjs(i.endDate) : undefined,
    price: i.price,
    startDate: i.startDate ? dayjs(i.startDate) : undefined,
    thumbnail: <></>,
    ignoreEventTime: i.ignoreEventTime,
    tags: i.tags ?? []
  }))


  return events
}

export const EventList = (props: {
  language: string,
  includeTags?: string[],
  excludeTags?: string[]
}) => {
  const [events, setEvents] = useState<Event[]>([])
  const listEndRef = useRef<HTMLDivElement | null>(null); // Reference to detect when the list end is visible
  const endReached = useRef(false);
  const [page, setPage] = useState(0);

  function updateEvents(e: Event[]) {
      setEvents(events => [
        ...events,
        ...e.filter(event => {
          // If includeTags is defined, check if the event tags include any of the includeTags
          const includesTag = props.includeTags ? props.includeTags.some(tag => event.tags.includes(tag)) : true;

          // If excludeTags is defined, check if the event tags do not include any of the excludeTags
          const excludesTag = props.excludeTags ? !props.excludeTags.some(tag => event.tags.includes(tag)) : true;

          // Return true if the event includes any of the includeTags and does not include any of the excludeTags
          return includesTag && excludesTag;
        })
      ])
  }

  useEffect(() => {

    const handleScroll = () => {
      const rect = listEndRef.current?.getBoundingClientRect();
      if ((rect?.top ?? Infinity) <= window.innerHeight && !endReached.current) {
        setPage((prev) => prev + 1);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      // Cleanup
      window.removeEventListener("scroll", handleScroll);
    };
  }, [])


  useEffect(() => {
    fetch('/events/event-data.json')
    .then(a => a.json())
    .then(a => {
      if(Array.isArray(a)){
        if(page <= (a.length-1)) {
          return fetchEvents(`/${a[page]}`, props.language)
        } else {
          endReached.current = true
          return Promise.resolve([])
        }
      } else {
        return Promise.reject(null)
      }})
      .then(updateEvents)

  }, [page])

  return <section className={styles.container}>
      {events.map(i => <EventItem key={i.eventUrl} event={i} />)}
      <div ref={listEndRef}></div> {/* Marker to determine when we're at the end of the list */}
  </section>
}