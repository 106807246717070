import React from "react"
import * as styles from "./style.module.scss"


interface ImageProps {
  image: JSX.Element
}

const Image = (props: ImageProps) => {
  return <div className={styles.container}>
      {props.image}
  </div>
}

export default Image