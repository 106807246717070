import { graphql, Link, useStaticQuery } from "gatsby"
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews"
import React, { useContext, useEffect, useState } from "react"
import { getLangPrefix, isHomepage, isPage } from "../../helper/helper"
import { LangContext, parseLanguage } from "../../helper/lang"
import { LanguageConfig } from "../../templates/page"
import { YellowBar } from "../util/YellowBar"
import * as styles from "./navigation.module.scss"

interface Page {
  uid: string
  page_title: string
}

export default function Navigation(props: {
  languageConfig: Record<string, LanguageConfig>
}) {

    const language = useContext(LangContext)
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    const {allPrismicMainNavigation} = useStaticQuery<Queries.Query>(allPagesQuery)
    const { data: allMenuEntries} = useMergePrismicPreviewData(allPrismicMainNavigation!)
    const menuEntries = allMenuEntries.nodes.filter(i => parseLanguage(i.lang) == language)[0]

    useEffect(() => {
      if(mobileMenuOpen) {
        window.document.body.classList.add("scrollLock")
      } else {
        window.document.body.classList.remove("scrollLock")
      }
    }, [mobileMenuOpen])

    const toggleMobileMenu = () => {
        setMobileMenuOpen(i => !i)
    }

    const pages: Page[] = (menuEntries.data.targets || []).map(target => {
      const document = target?.page?.document

      let uid: string = ''
      let maybePageTitle: string | null = null

      if(isPage(document)) {
        uid = document.uid
      }

      if(isPage(document) || isHomepage(document)) {
        maybePageTitle = document.data.page_title
      }

      const page_title: string = target?.link_text ?? maybePageTitle ??  ""

      return {
        page_title: page_title,
        uid: uid ? uid : ""
      }
    })

    return (<section className={styles.mainNav}>
      <div className={styles.logo}>
        <a href={`${getLangPrefix(language)}/`}>
          <img src="/pittlerwerke_logo.svg" alt="Zur Startseite" width={250} height={85}/>
        </a>
      </div>
      <div className={styles.mainMenu}>
        {pages.length > 0 && <div className={styles.mobileMenu}>
          <span className={`${styles.menuToggle} ${mobileMenuOpen ? styles.navOpen : ''}`} onClick={toggleMobileMenu}>
            <span className={`${styles.menuToggleBar} ${styles.menuToggleBarTop}`}></span>
            <span className={`${styles.menuToggleBar} ${styles.menuToggleBarMiddle}`}></span>
            <span className={`${styles.menuToggleBar} ${styles.menuToggleBarBottom}`}></span>
          </span>
        </div>}
        <nav className={`${styles.mobileMenuList} ${mobileMenuOpen ? styles.mobileMenuListOpen : ''}`}>
          <YellowBar />
          {
          pages
            .map((p, idx) => <div key={`${idx}${getLangPrefix(language)}/${p.uid}`} className={styles.mainMenuItem}>
              <Link activeClassName={styles.active} to={`${getLangPrefix(language)}/${p.uid}`}>{p.page_title}</Link>
              </div>)
          }
        </nav>
        <nav className={styles.desktopMenu}>
          {
          pages
            .map((p, idx) => <div key={`${idx}${getLangPrefix(language)}/${p.uid}`} className={styles.mainMenuItem}>
              <Link activeClassName={styles.active} to={`${getLangPrefix(language)}/${p.uid}`}>{p.page_title}</Link>
              </div>)
          }
        </nav>
        <div className={styles.langMenu}>
          {Object.entries(props.languageConfig).map(([key, config]) => {
            return config.isActive
              ? <span className={`${styles.langMenu__item} ${styles.langMenu__itemActive}`} key={key}>{config.displayString}</span>
              : <a className={`${styles.langMenu__item}`} key={key} href={`${config.urlPrefix}`}>{config.displayString}</a>
          })}
        </div>
      </div>
    </section>)
}

export const allPagesQuery = graphql`
  query NavigationEntries {
    allPrismicMainNavigation {
      nodes {
        lang
        _previewable
        data {
          targets {
            link_text
            page {
              target
              uid
              type
              lang
              document {
                ... on PrismicPage {
                  type
                  uid
                  data {
                    page_title
                  }
                }
                ... on PrismicHomepage {
                  type
                  data {
                    page_title
                  }
                }
              }
            }
          }
        }
      }
    }
  }`