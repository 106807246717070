// Code generated by Slice Machine. DO NOT EDIT.

import CallToAction from './CallToAction';
import EventList from './EventList';
import EventPromotion from './EventPromotion';
import ExternalCode from './ExternalCode';
import Fliesstext from './Fliesstext';
import Grid from './Grid';
import HeroImage from './HeroImage';
import Image from './Image';
import TextMitBild from './TextMitBild';
import Ueberschrift from './Ueberschrift';

export {
	CallToAction,
	EventList,
	EventPromotion,
	ExternalCode,
	Fliesstext,
	Grid,
	HeroImage,
	Image,
	TextMitBild,
	Ueberschrift,
};

export const components = {
	call_to_action: CallToAction,
	event_list: EventList,
	event_promotion: EventPromotion,
	external_code: ExternalCode,
	headline_with_copy: Fliesstext,
	grid: Grid,
	hero_image: HeroImage,
	image: Image,
	text_mit_bild: TextMitBild,
	ueberschrift: Ueberschrift,
};
