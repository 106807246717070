import React, { PropsWithChildren } from 'react'
import { PrismicRichText, SliceComponentProps } from '@prismicio/react'
import { GridItem, GridItemWithText} from "../../components/slices/Grid"
import { graphql } from 'gatsby'
import { linkResolver } from '../../helper/helper'
import { Masonry } from '@mui/lab'
import * as styles from "./style.module.scss"


function renderGridItem(item: Queries.PrismicGridDefaultItem | Queries.PrismicGridGridElementsWithTextItem) {
  switch(item.grid_element?.document?.type) {
    case "community_member": {
      const communityMember = (item.grid_element.document?.data as Queries.PrismicCommunityMemberDataType)
      const backgroundImage = item.image?.localFile?.childImageSharp?.gatsbyImageData ?? communityMember.cover_image?.localFile?.childImageSharp?.gatsbyImageData
      const title  = item.title ?? communityMember.name
      const WrapperItem = item.link?.url
        ? (p: PropsWithChildren) => <a href={item.link!.url!} target="_blank" className={styles.linkWrapper}>{p.children}</a>
        : (p: PropsWithChildren) => <React.Fragment>{p.children}</React.Fragment> ;
      return <WrapperItem>
        <GridItem
          key={item.grid_element?.id|| "key"}
          title={title ?? "Kein Titel"}
          backgroundImage={backgroundImage}
          target={item.grid_element?.url ?? "404"}
        />
      </WrapperItem>
    }
    default: {
      const backgroundImage = item.image?.localFile?.childImageSharp?.gatsbyImageData
      return <GridItem
          key={item.grid_element?.id ?? "key"}
          title={item.title ?? "Kein Titel"}
          backgroundImage={backgroundImage}
          target={item.grid_element?.url ?? "404"}
        />

    }
  }
}

function renderGridItemWithText(item: Queries.PrismicGridGridElementsWithTextItem) {
  switch(item.grid_element?.document?.type) {
    case "community_member": {
      const communityMember = (item.grid_element.document?.data as Queries.PrismicCommunityMemberDataType)
      const backgroundImage = item.image?.localFile?.childImageSharp?.gatsbyImageData ?? communityMember.cover_image?.localFile?.childImageSharp?.gatsbyImageData
      const title  = item.title ?? communityMember.name
      const description = communityMember.description
      const WrapperItem = item.link?.url
        ? (p: PropsWithChildren) => <a href={item.link!.url!} className={styles.linkWrapper} target="_blank">{p.children}</a>
        : (p: PropsWithChildren) => <React.Fragment>{p.children}</React.Fragment> ;
      return <WrapperItem><GridItemWithText
        key={item.grid_element?.id!!}
        title={title ?? "Kein Titel"}
        backgroundImage={backgroundImage}
        target={item.grid_element?.url!!}
        description={description ? <PrismicRichText
              linkResolver={linkResolver}
              field={description.richText}
          /> : <></>}
        /></WrapperItem>
    }
    default: {
      const backgroundImage = item.image?.localFile?.childImageSharp?.gatsbyImageData

      return <GridItemWithText
        key={item.grid_element?.id}
        title={item.title ?? "Kein Titel"}
        backgroundImage={backgroundImage}
        target={item.grid_element?.url!!}
        description={<></>}
        />

    }
  }
}

const Grid = ({ slice }: SliceComponentProps<Queries.PrismicGrid>) => {
  return <div className={styles.container}>
    <Masonry columns={{
        xs: 1,
        sm: 2,
        md: slice.primary.numberOfColumns || 3
      }} spacing={2} sx={{maxWidth: 1500, margin: "0 auto"}}>
      {slice.items.map(i => slice.variation == 'gridElementsWithText' ? renderGridItemWithText(i) : renderGridItem(i))}
    </Masonry>
  </div>
}

export default Grid

export const fragment = graphql`
fragment PrismicCommunityMember on PrismicAllDocumentTypes {
  ... on PrismicCommunityMember {
    type
    data {
      name
      cover_image {
        localFile {
          childImageSharp {
            gatsbyImageData(layout:CONSTRAINED)
          }
        }
      }
      description {
        text
        richText
        html
      }
    }
  }
}
fragment PrismicGrid on PrismicGrid {
  ... on PrismicGridGridElementsWithText {
    variation
    id
    primary {
      numberOfColumns
    }
    items {
      grid_element {
        id
        url
        document {
          ...PrismicCommunityMember
        }
      }
      title
			link {
        url
      }
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
    }
  }
  ... on PrismicGridDefault {
    variation
    id
    primary {
      numberOfColumns
    }
    items {
      grid_element {
        id
        url
        document {
          ...PrismicCommunityMember
        }
      }
      title
			link {
        url
      }
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
    }
  }
}
`
