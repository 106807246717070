
import dayjs, { Dayjs } from "dayjs";
import React, { useContext } from "react";
import * as styles from "./style.module.scss"
import { Event } from "../model"
import { renderDateString } from "../util";
import { LangContext } from "../../../../helper/lang";


interface EventPromotionProps {
  events: Event[]
}


const EventPromotionListItem = (props: { event: Event, renderThumbnail?: boolean }) => {
  const {
    description,
    eventTitle,
    eventUrl,
    headerImage,
    thumbnail,
    endDate,
    startDate,
  } = props.event

  const lang = useContext(LangContext)

  return <div className={styles.eventItem}>
    {props.renderThumbnail && thumbnail ? thumbnail : headerImage}
    <div className={styles.eventItem__metaWrapper}>
      <div className={styles.eventItem__title}>
        {eventTitle}
      </div>
      <div className={styles.dateWrapper}>
        {renderDateString(lang, startDate, endDate, props.renderThumbnail ? 'short' : 'normal')}
      </div>
      {!props.renderThumbnail && <div>
        {description}
      </div>}
      <a href={eventUrl} className={styles.eventItem__link}>more</a>
    </div>
  </div>
}

const EventPromotionListHeader = (props: EventPromotionProps) => {
  const {
    events
  } = props

  const [first, second, third, fourth] = events

  return <section className={styles.container}>
    <div className={styles.promoted}>
      {first && <EventPromotionListItem event={first} />}
    </div>
    <div className={styles.secondary}>
      {second && <EventPromotionListItem
        event={second}
      />}
    </div>
    <div className={styles.regular}>
      {third && <EventPromotionListItem
        renderThumbnail
        event={third}
      />}
    </div>
    <div className={styles.regular}>
      {fourth && <EventPromotionListItem
        renderThumbnail
        event={fourth}
      />}
    </div>
  </section>
}

export default EventPromotionListHeader