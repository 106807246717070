import React from 'react'
import * as styles from './style.module.scss'

interface HeadlineWithCopyProps {
  title: JSX.Element
  copy: JSX.Element
  variant?: "v1" | "v2"
}

const HeadlineWithCopy = (props: HeadlineWithCopyProps) => {
  const variant = props.variant ?? "v1";
  const containerClass = [
    styles.container,
    variant == "v2" ? styles.containerVariant2 : styles.containerVariant1
  ].join(" ")

  return <section className={containerClass}>
    <div className={styles.headerWrapper}>
      { props.title }
    </div>

    <div className={styles.contentWrapper}>
      { props.copy}
    </div>
  </section>
}

export default HeadlineWithCopy