import React, { useContext } from "react"
import { LangContext, LANGUAGE } from "../../../../helper/lang"
import { Event } from "../model"
import * as styles from "./event_item.module.scss"

interface EventMetaTranslation {
    until: string
    soon: string
    start: string
    end: string
    entry: string
    free_entry: string
}

const TRANSLATIONS: Record<LANGUAGE, EventMetaTranslation> = {
  [LANGUAGE.ENGLISH]: {
    until: "until",
    soon: "soon",
    end: "End",
    entry: "Entry",
    free_entry: "Free",
    start: "Start"

  },
  [LANGUAGE.GERMAN]: {
    until: "bis",
    soon: "demnächst",
    end: "Ende",
    entry: "Eintritt",
    free_entry: "Frei",
    start: "Beginn"
  }
}

export const EventItem = (props: {event: Event}) => {
  const {
    startDate,
    endDate,
    eventUrl
  } = props.event

  const lang = useContext(LangContext)

  return <div className={styles.eventItemContainer}>
    <div className={styles.eventItemContainer__dateContainer}>
      <div className={styles.dateContainer}>
        <span className={styles.dateContainer__day}>{startDate?.format("DD")}</span>
        <span className={styles.dateContainer__month}>{startDate?.format("MMM")}</span>
      </div >
     {(endDate && !endDate.isSame(startDate, "day")) && <>
      <div className={styles.dateContainerSplit}>
      –
      </div>
      <div className={styles.dateContainer}>
          <span className={styles.dateContainer__day}>{endDate?.format("DD")}</span>
          <span className={styles.dateContainer__month}>{endDate?.format("MMM")}</span>
        </div>
      </>}
    </div>
    <div className={styles.eventItemContainer__metaContainer}>
      <a className={styles.metaContainer__title} href={eventUrl}>
        {props.event.eventTitle}
      </a>
      <div>
        {props.event.description}
      </div>
       <div className={styles.metaInfoWrapper}>
        <div className={styles.metaInfoWrapper__dateWrapper}>
          {(!!props.event.ignoreEventTime) == false && <>
            {startDate && <div className={styles.metaInfoContainer}>
              <span className={styles.metaInfoContainer__heading}>
                {TRANSLATIONS[lang].start}
              </span>
              <span className={styles.metaInfoContainer__value}>
                {startDate.format("HH:mm")}
              </span>
            </div>}

            {endDate && <div className={styles.metaInfoContainer}>
              <span className={styles.metaInfoContainer__heading}>
                {TRANSLATIONS[lang].end}
              </span>
              <span className={styles.metaInfoContainer__value}>
                {endDate.format("HH:mm")}
              </span>
            </div>}
          </>
          }
        </div>
        {props.event.price != undefined && <div className={styles.metaInfoContainer}>
          <span className={styles.metaInfoContainer__heading}>
            {TRANSLATIONS[lang].entry}
          </span>
          <span className={styles.metaInfoContainer__value}>
            {
            props.event.price == 0
              ? <>{TRANSLATIONS[lang].free_entry}</>
              : <>{props.event.price}&nbsp;€ </>
            }
          </span>
        </div>}
      </div>
    </div>
  </div>
}